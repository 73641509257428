<template>
  <div
    class="m-order-header"
    :class="{ 'm-order-header--is-hide': isHeaderHide }"
  >
    <div class="header-summary">
      <div class="summary-order">
        <SfButton
          v-if="isMobile"
          aria-label="back"
          class="sf-button--pure sf-bar__icon"
          @click="closeOrder"
        >
          <span class="icon-back" />
        </SfButton>
        <span v-else class="back-action" @click="closeOrder" />
        <div class="summary-order__image">
          <span class="summary-order__image-icon summary-order__image-icon-online"
                :class="{'is-mobile': isMobile}"
                v-if="order.type==='online'"
          />
          <span class="summary-order__image-icon summary-order__image-icon-offline"
                :class="{'is-mobile': isMobile}"
                v-if="order.type==='offline'"
          />
        </div>
        <div v-if="order.type==='online'" class="summary-order__type">
          {{ $t('Online order') }}
        </div>
        <div v-if="order.type==='offline'" class="summary-order__type">
          {{ $t('Purchase in a store') }}
        </div>
        <div v-if="order.type==='online'" class="summary-order__number">
          № {{ order.number }}
        </div>
      </div>
      <div class="summary-info">
        <div class="summary-info__date">
          {{ order.date }}
        </div>
        <div
          v-if="order.type==='online' && order.statusInfo.group"
          class="summary-status"
          :class="order.statusInfo.class"
        >
          <div class="summary-status__text">
            {{ order.statusInfo.title }}
          </div>
        </div>
        <div class="summary-bonus" v-if="!isZeroValue(order.bonus_credit)">
          <ABonusIcon />
          <span class="bonus-value">
            +{{ order.bonus_credit }}₴
          </span>
          <span class="bonus-title">
            {{ $t('bonuser') }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SfButton } from '@storefront-ui/vue';
import { getIsZeroValue } from 'theme/helpers/orders';
import DeviceType from 'theme/mixins/DeviceType';
import ABonusIcon from 'theme/components/atoms/ABonusIcon.vue';

export default {
  name: 'MAccountOrderHeader',
  components: {
    ABonusIcon,
    SfButton
  },
  mixins: [DeviceType],
  data () {
    return {}
  },
  props: {
    order: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    isHeaderHide () {
      return this.$store.state.ui.microcart || this.$store.state.ui.searchpanel || this.$store.state.ui.isMobileCatalog || this.$store.state.ui.isMobileMenu
    }
  },
  methods: {
    closeOrder () {
      this.$emit('closeOrder')
    },
    isZeroValue (value) {
      return getIsZeroValue(value)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~theme/css/breakpoints";
@import "~theme/css/components/orders/orders";
@import '~theme/css/fonts';

.m-order-header {
  background-color: var(--white);
  display: flex;
  align-items: start;
  padding: var(--spacer-20) 0;
  border-bottom: 1px solid var(--light-gray);

  @include for-desktop {
    margin: 0 var(--spacer-50);
  }

  @include for-tablet {
    margin: 0 var(--spacer-30);
  }

  @media (max-width: $mobile-max){
    padding: var(--spacer-15) var(--spacer-10);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: calc(var(--header-sticky-index, 2) + 1);
    border-bottom-width: 0;
  }

  &--is-hide {
    @media (max-width: $mobile-max){
      z-index: 0;
    }
  }

  .back-action {
    cursor: pointer;
    @include font-icon(var(--icon-arrow-back));

    &:before {
      font-size: 24px;
      color: var(--black);
    }
  }

  .header-summary {
    font-family: var(--font-family-inter);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    flex-grow: 1;

    @media (max-width: $mobile-max) {
      flex-direction: column;
      margin-left: 0;
      width: 100%;
      align-items: start;
    }

    .summary-order {
      display: flex;
      flex-direction: row;
      align-items: center;

      @media (max-width: $mobile-max) {
        margin-left: var(--spacer-10);
      }

      .sf-bar__icon {
        width: 30px;
        height: 30px;
      }

      .icon-back {
        @include font-icon(var(--icon-arrow-left));

        &:before {
          color: var(--black);
          font-size: var(--font-size-12);
        }
      }

      &__image {
        margin-left: var(--spacer-24);

        @media (max-width: $mobile-max) {
          margin-left: var(--spacer-16);
        }
      }

      &__type {
        margin-left: var(--spacer-10);
        font-size: var(--font-size-20);
        font-weight: var(--font-medium);
        color: var(--black);
        white-space: nowrap;

        @media (max-width: $mobile-max) {
          margin-left: var(--spacer-6);
          font-weight: var(--font-normal);
          font-size: var(--font-size-12);
        }
      }

      &__number {
        margin-left: var(--spacer-4);
        font-size: var(--font-size-20);
        font-weight: var(--font-medium);
        color: var(--black);
        white-space: nowrap;

        @media (max-width: $mobile-max) {
          font-weight: var(--font-normal);
          font-size: var(--font-size-12);
        }
      }
      &__image {
        &-icon {
          &-online {
            @include font-icon(var(--icon-cart));

            &:before {
              color: #87A381;
              font-size: var(--font-size-24);
            }

            &.is-mobile {
              &:before {
                color: #87A381;
                font-size: var(--font-size-16);
              }
            }
          }

          &-offline {
            @include font-icon(var(--icon-offline));

            &:before {
              color: var(--orange);
              font-size: var(--font-size-24);
            }

            &.is-mobile {
              &:before {
                color: var(--orange);
                font-size: var(--font-size-16);
              }
            }
          }
        }
      }
    }

    .summary-info {
      display: flex;
      flex-grow: 1;
      justify-content: space-between;

      @media (max-width: $mobile-max) {
        width: 100%;
        padding-top: var(--spacer-20);
        margin-left: var(--spacer-0);
      }

      &__date {
        margin-left: var(--spacer-22);
        font-size: var(--font-size-12);
        color: var(--dark-gray);
        white-space: nowrap;
        align-self: end;

        @media (max-width: $mobile-max) {
          margin-left: 0;
          align-self: center;
        }
      }

      .summary-status {
        flex-shrink: 0;
        height: var(--spacer-24);
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: var(--font-size-11);
        line-height: var(--font-size-13);
        margin-left: var(--spacer-75);
        border-radius: var(--spacer-5);

        @media (max-width: $mobile-max) {
          margin-left: 0;
        }

        &__text {
          padding: var(--spacer-5) var(--spacer-10);
        }
      }

      .summary-bonus {
        flex-shrink: 0;
        display: flex;
        font-size: var(--font-size-14);
        align-items: center;
        height: var(--spacer-24);
        background-color: var(--orange-light-background);
        border-radius: var(--spacer-22);
        margin: 0 0 0 var(--spacer-10);
        color: var(--orange);

        .bonus-value {
          font-size: var(--font-size-14);
          font-weight: var(--font-medium);
          padding: 0 var(--spacer-5);
        }

        .bonus-title {
          font-size: var(--font-size-10);
          font-weight: var(--font-normal);
          padding-right: var(--spacer-5);
        }
      }
    }
  }
}
</style>
