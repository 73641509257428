<template>
  <div class="m-order-products">
    <div class="order-products__header">
      {{ $t('Products in order ({count})', { count: products.length }) }}
    </div>
    <div
      v-for="product in products"
      :key="product.id"
      class="product-item"
    >
      <MAccountOrderCard
        :product="product"
        :image="product.image || product.src"
        class="product-item__card"
      />
    </div>
  </div>
</template>

<script>
import DeviceType from 'theme/mixins/DeviceType';
import MAccountOrderCard from 'theme/components/molecules/m-account-order-card';

export default {
  name: 'MAccountOrderProducts',
  components: {
    MAccountOrderCard
  },
  mixins: [DeviceType],
  data () {
    return {}
  },
  props: {
    products: {
      type: Array,
      default: () => []
    },
    orderType: {
      type: String,
      default: () => ''
    }
  },
  computed: {
    existingProducts () {
      return this.products.filter(e => e.productExist) || []
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

.order-products {
  &__header {
    font-family: var(--font-family-secondary);
    font-size: var(--font-size-16);
    font-weight: var(--font-medium);
    line-height:var(--font-size-20);
    padding-bottom: var(--spacer-15);
    border-bottom: 1px solid var(--light-gray);

    @media (max-width: $mobile-max) {
      border-bottom: 2px solid var(--light-gray);
    }
  }

  .product-item {
    border-bottom: 1px solid var(--light-gray);

    @media (max-width: $mobile-max) {
      border-bottom: 2px solid var(--light-gray);
    }
  }

  .product-item:last-of-type {
    border-bottom: none;
  }

  &__add-to-cart {
    margin-top: var(--spacer-12);
  }
}
</style>
