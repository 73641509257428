<template>
  <div v-if="downloadLink" class="m-order-fiscal">
    <div class="m-order-fiscal__download">
      <SfButton
        class="sf-button--full-width sf-button--outline"
        @click="openFiscal"
      >
        {{ $t('See cheque') }}
      </SfButton>
    </div>
  </div>
</template>

<script>
import { SfButton } from '@storefront-ui/vue';

export default {
  name: 'MAccountOrderFiscal',
  components: {
    SfButton
  },
  data () {
    return {}
  },
  props: {
    order: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    downloadLink () {
      return this.order.payment_receipt_link
    }
  },
  methods: {
    openFiscal () {
      window.open(this.downloadLink, '_blank');
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

.m-order-fiscal {
  margin-bottom: var(--spacer-25);
  font-family: var(--font-family-secondary);
  color: var(--black);

  @media (max-width: $mobile-max) {
    padding-top: var(--spacer-25);
    border-top: 1px solid var(--gray3);
  }
}
</style>
