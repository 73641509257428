<template>
  <div class="m-order-details">
    <SfAccordion v-if="order.type === 'online'" :show-chevron="true" transition="sf-expand" :open="accordionOpen">
      <SfAccordionItem
        :header="$t('Order details')"
      >
        <div class="accordion-item">
          <div class="header-row">
            <span class="header-row__icon header-row__icon-location" />
            <div class="header-row__title">
              {{ $t('Delivery method') }}
            </div>
          </div>
          <div class="main-row">
            <span v-if="order.type==='online'">{{ getDeliveryMethod(order.delivery_method) }}:</span>
            {{ order.delivery_address }}
          </div>
          <div v-if="order.recipient" class="main-row">
            <span v-if="order.type==='online'">{{ $t('Recipient') }}:</span>
            {{ order.recipient }}
          </div>
        </div>
        <div v-if="!isNewPost" class="accordion-item">
          <div class="header-row">
            <span class="header-row__icon header-row__icon-time" />
            <div class="header-row__title">
              {{ $t('Delivery time') }}
            </div>
          </div>
          <div class="main-row">
            {{ getDeliveryDateTime }}
          </div>
        </div>
        <div class="accordion-item">
          <div class="header-row">
            <span class="header-row__icon header-row__icon-card" />
            <div class="header-row__title">
              {{ $t('Payment method') }}
            </div>
          </div>
          <div class="main-row">
            <div class="main-row__payment_method">
              {{ getPaymentMethod(order.payment_method) }}
            </div>
            <div
              v-if="order.payment_method === 'CARD_ONLINE' && order.payment_card_mask"
              class="main-row__payment_card"
            >
              <svg v-if="order.payment_card_type === 'MasterCard'" width="20" height="13" viewBox="0 0 43 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M27.2 3.5H15.8v21h11.4v-21Z" fill="#FF5F00" />
                <path d="M16.5 14a13.6 13.6 0 0 1 5-10.5A12.8 12.8 0 0 0 2.5 7a13.6 13.6 0 0 0 5.3 19 12.7 12.7 0 0 0 13.7-1.4 13.3 13.3 0 0 1-5-10.5Z" fill="#EB001B" />
                <path d="M42.5 14a13.6 13.6 0 0 1-7.3 12 12.7 12.7 0 0 1-13.7-1.5 13.3 13.3 0 0 0 5-10.5 13.6 13.6 0 0 0-5-10.5 12.8 12.8 0 0 1 19 3.4c1.3 2.1 2 4.6 2 7.1ZM41.3 22.3v-.5h.1-.4.2v.5Zm.8 0v-.6H42l-.2.4-.1-.4h-.2v.6h.1v-.4l.2.3.2-.3v.4h.1Z" fill="#F79E1B" />
              </svg>
              <svg v-if="order.payment_card_type === 'Visa'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 61 20" width="30" height="12">
                <path fill="#26337a" d="m21.5 19.5 3-19h5l-3 19h-5M44.2 1c-1-.4-2.5-.9-4.4-.9-4.8 0-8.3 2.6-8.3 6.3 0 2.8 2.5 4.3 4.3 5.2 2 1 2.6 1.5 2.6 2.4 0 1.3-1.6 1.8-3 1.8-2 0-3-.2-4.6-1l-.6-.3-.7 4.3c1.1.5 3.3 1 5.5 1 5.1 0 8.5-2.5 8.5-6.5 0-2.2-1.3-3.8-4.1-5.2-1.7-.9-2.8-1.4-2.8-2.3 0-.8 1-1.7 2.8-1.7 1.7 0 2.8.4 3.7.8l.5.2.6-4.2M50.8 12.8l2-5.3.6-1.9.3 1.7 1.2 5.5h-4.1Zm6-12.3H53C52 .5 51 .8 50.5 2l-7.3 17.5h5.1l1-3 6.4.1.6 2.9h4.5L56.8.5ZM17.4.5l-4.8 13-.6-2.7c-.9-3-3.6-6.3-6.8-8l4.4 16.7h5.2l7.8-19h-5.2" />
                <path fill="#ed982d" d="M8 .5H.3L0 .9a16.5 16.5 0 0 1 12 10L10.2 2C10 .9 9.2.5 8.1.5" />
              </svg>
              <div class="card-number">
                <span>{{ order.payment_card_mask }}</span>
              </div>
            </div>
          </div>
        </div>
        <div v-if="order.shipping_name || order.shipping_phone" class="accordion-item ">
          <div class="header-row">
            <span class="header-row__icon header-row__icon-user" />
            <div class="header-row__title">
              {{ $t('Contact Information') }}
            </div>
          </div>
          <div class="main-row">
            <div v-if="order.shipping_phone" class="main-row-1">
              <div class="main-row__action_name">
                {{ $t('Phone') }}
              </div>
              <div class="main-row__action_value">
                {{ order.shipping_phone }}
              </div>
            </div>
            <div v-if="order.shipping_name" class="main-row-2">
              <div class="main-row__action_name">
                {{ $t('First name') }}
              </div>
              <div class="main-row__action_value">
                {{ order.shipping_name }}
              </div>
            </div>
          </div>
        </div>
        <div v-if="!isNewPost" class="accordion-item">
          <div class="header-row">
            <span class="header-row__icon header-row__icon-refresh" />
            <div class="header-row__title">
              {{ $t('Replacement and packaging of goods') }}
            </div>
          </div>
          <div class="main-row">
            <div class="main-row-1">
              <div class="main-row__action_name">
                {{ $t('Replacement') }}
              </div>
              <div class="main-row__action_value">
                {{ getReplacement(order.replacement) }}
              </div>
            </div>
            <div class="main-row-2">
              <div class="main-row__action_name">
                {{ $t('Packaging of products') }}
              </div>
              <div class="main-row__action_value">
                {{ getPackaging(order.packaging) }}
              </div>
            </div>
          </div>
        </div>
      </SfAccordionItem>
    </SfAccordion>
  </div>
</template>

<script>
import { SfAccordion } from '@storefront-ui/vue';
import {
  getDeliveryMethod,
  getPaymentMethod,
  getReplacement,
  getPackaging,
  getFormattedDateTime
} from 'theme/helpers/orders';
import DeviceType from 'theme/mixins/DeviceType';

export default {
  name: 'MAccountOrderDetails',
  components: {
    SfAccordion
  },
  mixins: [DeviceType],
  data () {
    return {}
  },
  props: {
    order: {
      type: Object,
      default: () => ({})
    },
    isNewPost: {
      type: Boolean,
      default: () => false
    }
  },
  computed: {
    getDeliveryDate () {
      if (!Array.isArray(this.order.timeslot)) return ''
      let date = this.order.timeslot[1]
      if (typeof date !== 'string') return ''

      date = date.split('-').reverse().join('-')
      return getFormattedDateTime(date, false)
    },
    getDeliveryTime () {
      if (!Array.isArray(this.order.timeslot)) return ''
      return this.order.timeslot[2]
    },
    getDeliveryDateTime () {
      const separator = this.getDeliveryDate && this.getDeliveryTime ? ', ' : ''
      return [this.getDeliveryDate, this.getDeliveryTime].filter(i => !!i).join(separator)
    },
    accordionOpen () {
      return this.order.statusInfo.group === 'non-fiscalized' ? this.$t('Order details') : ''
    }
  },
  methods: {
    getDeliveryMethod (method) {
      return getDeliveryMethod(method)
    },
    getPaymentMethod (method) {
      return getPaymentMethod(method)
    },
    getReplacement (value) {
      return getReplacement(value)
    },
    getPackaging (value) {
      return getPackaging(value)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
@import '~theme/css/fonts';

.m-order-details {
  .accordion-item {
    font-family: var(--font-family-inter);
    border-bottom: 3px solid var(--gray3);
    display: flex;
    flex-direction: column;
    padding: var(--spacer-25) 0;
  }
  ::v-deep {
    .sf-accordion-item__header {
      border-top: 3px solid var(--gray3);
      border-bottom: 3px solid var(--gray3);
      padding: var(--spacer-25) 0 var(--spacer-15);
      font-size: var(--font-size-16);
      font-weight: var(--font-medium);
      line-height:var(--font-size-19);
    }

    .sf-accordion-item__content {
      border: none;
    }

    .header-row {
      display: flex;
      align-items: center;
      position: relative;

      &__icon {
        &:before {
          color: var(--orange);
          font-size: var(--font-size-24);
        }
      }

      &__title {
        font-size: var(--font-size-16);
        font-weight: var(--font-medium);
        line-height:var(--font-size-20);
        margin-left: var(--spacer-12);
      }

      &__icon-location {
        @include font-icon(var(--icon-location));

        &:before {
          font-size: var(--font-size-22);
        }
      }
      &__icon-time {
        @include font-icon(var(--icon-clock));

        &:before {
          font-size: var(--font-size-20);
        }
      }
      &__icon-card {
        @include font-icon(var(--icon-credit-card));

        &:before {
          font-size: var(--font-size-18);
        }
      }
      &__icon-user {
        @include font-icon(var(--icon-user));
      }
      &__icon-refresh {
        @include font-icon(var(--icon-update));

        &:before {
          font-size: var(--font-size-20);
        }
      }
    }

    .main-row {
      margin-top: var(--spacer-10);
      padding-left: var(--spacer-33);
      font-size: var(--font-size-14);
      color: var(--dark-gray);
      line-height: var(--font-size-16);

      .main-row-1 {
        display: flex;
      }

      .main-row-2 {
        margin-top: var(--spacer-10);
        display: flex;
      }

      .main-row__payment_card {
        margin-top: var(--spacer-10);
        display: flex;
        align-items: center;

        .card-number {
          margin-left: var(--spacer-7);
          color: var(--black);
          font-size: var(--font-size-14);
          line-height: var(--font-size-17);
        }
      }

      &__action_name {
        flex: 0 0 var(--spacer-100);
        color: var(--dark-gray)
      }

      &__action_value {
        color: var(--black)
      }
    }
  }
}
</style>
